import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/Auth";
import API from "../api/api";
import AddWalletDialog from "./Onboarding/OnboardingDetails/OnboardingSummary/AddWallet";
import AddBankDialog from "./Onboarding/OnboardingDetails/OnboardingSummary/AddBank";
import Loader from "../components/Loader";
import { useToast } from "../contexts/ToastContext";
import IUnderstandDialog from "./Onboarding/OnboardingDetails/OnboardingSummary/IUnderstand";

enum Tabs {
  CRYPTO = "crypto",
  FIAT = "fiat",
}

const ConnectedAccounts: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.FIAT);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [externalWallets, setExternalWallets] = useState([]);
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [openBankForm, setOpenBankForm] = useState(false);
  const [openWalletForm, setOpenWalletForm] = useState(false);
  const auth = useAuth();
  const accountId = auth?.currentUser?.selectedAccount?.accountId;

  const handleSubmissionData = async (data: any) => {
    if (data) {
      setLoading(true);
      const { data: updatedBankAccounts } = await API.get(
        `business/bankAccounts/${accountId}`,
      );
      showToast("Bank account added successfully");
      setLoading(false);
      setBankAccounts(updatedBankAccounts);
      setOpenBankForm(false);
    } else {
      // setShowError(true);
    }
  };

  const handleWalletSubmissionData = async (body: any) => {
    if (body && accountId) {
      setLoading(true);
      const { data } = await API.post(
        `business/${accountId}/externalWallets`,
        body,
      );
      showToast("Wallet added successfully");
      setExternalWallets([...externalWallets, data]);
      setOpenWalletForm(false);
      setLoading(false);
      setShowDisclaimer(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!accountId) return;
        const url = `business/${accountId}/externalWallets`;
        const { data } = await API.get(url);
        console.log("data", data);
        setExternalWallets(data);
      } catch (error) {
        // Handle error here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [accountId]);

  useEffect(() => {
    const getBankAccounts = async () => {
      if (!accountId) return;
      const { data } = await API.get(`business/bankAccounts/${accountId}`);
      setBankAccounts(data);
    };
    getBankAccounts();

    return () => {
      setBankAccounts([]);
    };
  }, [accountId]);

  return (
    <>
      {loading && <Loader />}
      {showDisclaimer && (
        <IUnderstandDialog open={showDisclaimer} setOpen={setShowDisclaimer} />
      )}
      {openWalletForm && (
        <AddWalletDialog
          open={openWalletForm}
          setOpen={setOpenWalletForm}
          onSubmit={handleWalletSubmissionData}
        />
      )}
      {openBankForm && (
        <AddBankDialog
          open={openBankForm}
          setOpen={setOpenBankForm}
          onSubmit={handleSubmissionData}
        />
      )}
      <main className="bg-grey p-6">
        <div className="flex flex-row items-center mb-2">
          <h4 className="text-xl font-bold dark:text-black">
            Connected Accounts
          </h4>
        </div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto"></div>
        </div>
        <div className="border-b border-gray-200 dark:border-gray-700 flex direction-row align-center justify-between">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
            <li className="me-2">
              <button
                onClick={() => setSelectedTab(Tabs.FIAT)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.FIAT
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                Fiat
              </button>
            </li>
            <li className="me-2">
              <button
                onClick={() => setSelectedTab(Tabs.CRYPTO)}
                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-gray-600 dark:hover:text-gray-300 group ${
                  selectedTab === Tabs.CRYPTO
                    ? "text-lavender-600 border-lavender-600 dark:text-lavender-500 dark:border-lavender-500"
                    : "border-transparent"
                }`}
              >
                Crypto
              </button>
            </li>
          </ul>
        </div>

        {selectedTab === Tabs.CRYPTO && (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-sm font-semibold text-gray-900"
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-right text-sm font-semibold text-gray-900"
                        >
                          <button
                            type="button"
                            className="rounded bg-lavender-400 hover:bg-lavender-600 px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
                            onClick={() => setOpenWalletForm(true)}
                            disabled={loading || !accountId}
                          >
                            Add External Wallet
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {externalWallets?.map((wallt) => (
                        <tr key={wallt.id}>
                          <td className="whitespace-nowrap p-4 text-sm font-medium text-gray-900 sm:pl-6">
                            {wallt.description}
                          </td>
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                            {wallt.address}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedTab === Tabs.FIAT && (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Bank
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Routing Number
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Account Number
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-right text-sm font-semibold text-gray-900"
                        >
                          <button
                            type="button"
                            className="rounded bg-lavender-400 hover:bg-lavender-600 px-2 py-1 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-lavender-600 disabled:bg-lavender-200 disabled:text-lavender-700 disabled:opacity-50"
                            onClick={() => setOpenBankForm(true)}
                            disabled={loading || !accountId}
                          >
                            Add Bank Account
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {bankAccounts.map((bank) => (
                        <tr key={bank.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {bank.description}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {bank.bankName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {bank.type}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {bank.routingNumber}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            ******{bank.last4}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default ConnectedAccounts;
